import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import styled from "styled-components"

import PageHeader from "../components/pageHeader/PageHeader"
import TopPart from "../pagesParts/history/TopPart"
import MidPart from "../pagesParts/history/MidPart"
import BotPart from "../pagesParts/history/BotPart"

const StyledMainWrapper = styled.main`
  margin-top: 60px;
`

export default function HistoryPage() {
  return (
    <Layout>
      <SEO
        title="Notre Histoire"
        description="Depuis 1999, nous équipons nos clients de matériel informatique fiable et les aidons à disposer d'un environnement de travail solide et sécurisé."
      />
      <StyledMainWrapper>
        <PageHeader
          heading="Notre Histoire"
          subheading="Depuis 1999, nous équipons nos clients de matériel informatique fiable et les aidons à disposer d'un environnement de travail solide et sécurisé."
        />
        <TopPart />
        <MidPart />
        <BotPart />
      </StyledMainWrapper>
    </Layout>
  )
}
